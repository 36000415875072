<template>
  <div>
    <b-row>
      <b-col cols="12" md="6">
        <vx-input
          v-model="formValue.refill_amount"
          :rules="rules.refill_amount"
          name="options.refill_amount"
          label="Refill amount"
          type="number"
        />
      </b-col>
      <b-col cols="12" md="6">
        <vx-input
          v-model="formValue.refill_period"
          :rules="rules.refill_period"
          name="options.refill_period"
          label="Refill period"
          type="number"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <vx-input
          v-model="formValue.opening_period"
          :rules="rules.opening_period"
          name="options.opening_period"
          label="Opening period"
          type="number"
        />
      </b-col>
      <b-col cols="12" md="6">
        <vx-input
          v-model="formValue.text_period"
          :rules="rules.text_period"
          name="options.text_period"
          label="Text period"
          type="number"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="3">
        <vx-checkbox
          v-model="formValue.use_text_criteria"
          name="options.use_text_criteria"
          label="Use text criteria"
        />
      </b-col>
      <b-col cols="12" md="3">
        <vx-checkbox
          v-model="formValue.use_refill_criteria"
          name="options.use_refill_criteria"
          label="Use refill criteria"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VxInput, VxCheckbox } from '@/components/form'
import { BCol, BRow } from 'bootstrap-vue'
import { reactive } from '@vue/composition-api/dist/vue-composition-api'
import { frontDefaultValidators } from '@/services/form/validationService'

export default {
  name: 'FreeCaseExtraFields',
  components: {
    BRow,
    BCol,
    VxInput,
    VxCheckbox
  },
  setup () {
    const formValue = reactive({
      refill_amount: 0,
      refill_period: 0,
      opening_period: 0,
      text_period: 0,
      use_refill_criteria: false,
      use_text_criteria: false
    })

    const rules = {
      refill_amount: frontDefaultValidators.number,
      refill_period: frontDefaultValidators.number,
      opening_period: frontDefaultValidators.number,
      text_period: frontDefaultValidators.number
    }

    return {
      formValue,
      rules
    }
  }
}
</script>
