<template>
  <vx-card>
    <vx-form
      v-slot="{ loading, can }"
      :resource="formResource"
      @submit="handleSubmit"
    >
      <b-row>
        <b-col cols="12" md="6">
          <vx-input
            v-model="formValue.title"
            :rules="rules.title"
            name="title"
            label="Title"
          />
        </b-col>

        <b-col cols="12" md="6">
          <vx-input
            v-model="formValue.description"
            :rules="rules.description"
            name="description"
            label="Description"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <vx-input
            v-model="formValue.slug"
            :rules="rules.slug"
            name="slug"
            label="Slug"
          />
        </b-col>

        <b-col cols="12" md="6">
          <vx-input
            v-model="formValue.main_price"
            :rules="rules.main_price"
            name="main_price"
            label="Price ($)"
            type="number"
            step="0.01"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <vx-input
            v-model="formValue.profitability"
            :rules="rules.profitability"
            name="profitability"
            label="Profitability"
            type="number"
            step="0.01"
          />
        </b-col>

        <b-col cols="12" md="6">
          <vx-input
            v-model="formValue.generation_period"
            :rules="rules.generation_period"
            name="generation_period"
            label="Generation period (hours)"
            type="number"
            step="1"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <vx-select-resource
            v-model="formValue.section_id"
            :resource="caseSectionsResource"
            :rules="rules.section_id"
            :searchable="false"
            :reduce="({ id }) => id"
            options-label="title"
            name="section_id"
            label="Section"
          />
        </b-col>

        <b-col cols="12" md="6">
          <vx-select-resource
            v-model="formValue.chest_type_id"
            :resource="caseTypesResource"
            :resource-mapper="caseTypesResourceMapper"
            :initial-options="caseTypesInitialOptions"
            :rules="rules.chest_type_id"
            :searchable="false"
            :reduce="(type) => type.id"
            options-label="title"
            name="chest_type_id"
            label="Case type"
          />
        </b-col>
      </b-row>

      <vx-card v-if="extraFields">
        <h5 class="mb-1">
          Case type options
        </h5>
        <component
          :is="extraFields.component"
          v-if="extraFields"
          ref="extraFieldsRef"
        />
      </vx-card>

      <b-row>
        <b-col cols="12" md="3">
          <vx-select
            v-model="formValue.label"
            :rules="rules.label"
            :options="caseLabelsList"
            options-label="label"
            name="label"
            label="Label"
          />

          <vx-checkbox
            v-model="formValue.is_disabled"
            name="is_disabled"
            label="Is Disabled"
          />
          <vx-checkbox
            v-model="formValue.is_manual"
            name="is_manual"
            label="Is Manual"
          />
          <vx-checkbox
            v-model="formValue.hidden_in_section"
            name="hidden_in_section"
            label="Hidden in section"
            disabled
          />
        </b-col>

        <b-col cols="12" md="3">
          <vx-image-uploader
            v-model="formValue.file_id"
            :rules="rules.file_id"
            name="file_id"
            label="Image"
          />
        </b-col>

        <b-col cols="12" md="6">
          <vx-json
            v-model="formValue.wds_parameters"
            label="WDS parameters"
            mode="code"
            :modes="['code']"
            name="wds_parameters"
          />
        </b-col>
      </b-row>

      <vx-button
        :loading="loading"
        :can="can"
        variant="primary"
        type="submit"
      >
        Save
      </vx-button>
    </vx-form>
  </vx-card>
</template>

<script>
import { computed, onBeforeMount, reactive, ref } from '@vue/composition-api'
import { isEmpty } from 'lodash'
import { BCol, BRow } from 'bootstrap-vue'
import VxCard from '@/components/VxCard'
import VxButton from '@/components/buttons/VxButton'
import {
  VxForm,
  VxInput,
  VxCheckbox,
  VxSelectResource,
  VxSelect,
  VxImageUploader
} from '@/components/form'
import FreeCaseExtraFields from '@/views/cases/case-new-editor/FreeCaseExtraFields'
import QuantityLimitedExtraFields from '@/views/cases/case-new-editor/QuantityLimitedExtraFields'
import TimeLimitedExtraFields from '@/views/cases/case-new-editor/TimeLimitedExtraFields'
import BroCoinExtraFields from '@/views/cases/case-new-editor/BroCoinExtraFields'
import {
  caseIconTypes,
  cases,
  caseTypes,
  passDataToResource,
  useResource,
  sections,
  caseLabelDefault
} from '@/services/resources'
import { formatToCents } from '@/services/utils'
import { apiDefaultValidators } from '@/services/form'
import VxJson from '@/components/form/VxJson'
import router, { path } from '@/router'

export default {
  name: 'CaseNewEditor',
  components: {
    VxJson,
    BRow,
    BCol,
    VxButton,
    VxImageUploader,
    VxSelect,
    VxCard,
    VxForm,
    VxInput,
    VxSelectResource,
    VxCheckbox
  },
  setup () {
    const caseLabelsList = ref([])

    const formValue = reactive({
      title: '',
      description: '',
      slug: '',
      chest_type_id: 0,
      main_price: 0,
      is_disabled: false,
      is_manual: false,
      section_id: null,
      file_id: null,
      label: caseLabelDefault,
      min_category_amount: 0,
      generation_period: null,
      profitability: 0.9,
      hidden_in_section: true,
      wds_parameters: {}
    })

    const {
      maxTinyTextLength,
      maxMediumIntUnsignedWithDecimal
    } = apiDefaultValidators
    const rules = {
      title: {
        required: true,
        min: 2,
        max: maxTinyTextLength
      },
      slug: {
        required: true,
        min: 2,
        max: maxTinyTextLength
      },
      description: {
        max: 2000
      },
      main_price: {
        required: true,
        decimal: true,
        min_value: 0.01,
        max_value: maxMediumIntUnsignedWithDecimal
      },
      section_id: {
        required: true,
        numeric: true
      },
      generation_period: {
        generation_period_validator: true
      },
      profitability: {
        required_if: ['is_manual', false],
        between: [0.7, 1]
      },
      label: {
        required: true
      },
      file_id: {
        required: true
      }
    }

    const frontToBackMapper = () => {
      const {
        generation_period,
        chest_type_id,
        wds_parameters,
        main_price,
        label,
        ...rest
      } = formValue

      const res = {
        ...rest,
        main_price: formatToCents(main_price),
        chest_type_id: chest_type_id === 0 ? null : chest_type_id,
        wds_parameters: isEmpty(wds_parameters) ? null : wds_parameters,
        generation_period: generation_period && generation_period * 3600,
        label: label === caseLabelDefault ? null : label
      }

      if (extraFieldsRef.value) {
        const { frontToBackMapper, formValue } = extraFieldsRef.value

        res.options = {
          ...(frontToBackMapper ? frontToBackMapper() : formValue)
        }
      }

      return res
    }

    const formResource = passDataToResource(cases.create, {
      frontToBackMapper
    })

    const handleSubmit = async ([err]) => {
      if (err) return

      await router.push(path.cases)
    }

    const caseSectionsResource = sections.getAll

    const caseTypesResource = cases.types
    const caseTypesInitialOptions = [{
      id: 0,
      title: 'Usual case',
      name: caseTypes.usualCase
    }]
    const allCaseTypes = ref([...caseTypesInitialOptions])
    const caseTypesResourceMapper = (types) => {
      allCaseTypes.value.push(...types)
      return types
    }

    const extraFieldsByType = {
      [caseTypes.usualCase]: null,
      [caseTypes.free]: {
        component: FreeCaseExtraFields
      },
      [caseTypes.quantityLimited]: {
        component: QuantityLimitedExtraFields
      },
      [caseTypes.timeLimited]: {
        component: TimeLimitedExtraFields
      },
      [caseTypes.brcPriced]: {
        component: BroCoinExtraFields
      }
    }

    const extraFieldsRef = ref(null)
    const extraFields = computed(() => {
      const type = allCaseTypes.value.find(({ id }) => id === formValue.chest_type_id)
      return extraFieldsByType[type.name]
    })

    onBeforeMount(async () => {
      const {
        can: canLabels,
        callRequest: getLabels
      } = useResource(cases.labels)

      if (canLabels) {
        const [, res] = await getLabels()

        caseLabelsList.value = [caseLabelDefault, ...res.data]
      }
    })

    return {
      formResource,
      formValue,
      rules,
      handleSubmit,

      caseSectionsResource,

      caseTypesResource,
      caseTypesInitialOptions,
      caseTypesResourceMapper,

      caseLabelsList,

      extraFields,
      extraFieldsRef,

      caseIconTypes
    }
  }
}
</script>
