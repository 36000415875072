<template>
  <b-row>
    <b-col cols="12" md="6">
      <vx-input
        v-model="formValue.quantity_limit"
        :rules="rules.quantity_limit"
        name="options.quantity_limit"
        label="Quantity limit"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { VxInput } from '@/components/form'
import { reactive } from '@vue/composition-api/dist/vue-composition-api'
import { frontDefaultValidators } from '@/services/form/validationService'

export default {
  name: 'QuantityLimitedExtraFields',
  components: {
    BRow,
    BCol,
    VxInput
  },
  setup () {
    const formValue = reactive({
      quantity_limit: 0
    })

    const rules = {
      quantity_limit: frontDefaultValidators.number
    }

    return {
      formValue,
      rules
    }
  }
}
</script>
