<template>
  <b-row>
    <b-col cols="12" md="6">
      <vx-date-range-picker
        v-model="formValue.range"
        :rules="rules.range"
        :format="dateFormat"
        name="options.range"
        label="Date range"
        type="date"
      />
    </b-col>
  </b-row>
</template>

<script>
import { VxDateRangePicker } from '@/components/form'
import { BCol, BRow } from 'bootstrap-vue'
import { datePickerFormats } from '@/services/utils'
import { reactive } from '@vue/composition-api'

export default {
  name: 'TimeLimitedExtraFields',
  components: {
    BRow,
    BCol,
    VxDateRangePicker
  },
  setup () {
    const formValue = reactive({
      range: [null, null]
    })

    const rules = {
      range: {
        required_range: true
      }
    }

    const frontToBackMapper = () => {
      const [starts_at, ends_at] = formValue.range
      return { starts_at, ends_at }
    }

    return {
      formValue,
      rules,
      frontToBackMapper,
      dateFormat: datePickerFormats.date
    }
  }
}
</script>
