<template>
  <b-row>
    <b-col cols="12" md="6">
      <vx-input
        v-model="formValue.price"
        :rules="rules.price"
        name="options.price"
        label="Price (BRC)"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { VxInput } from '@/components/form'
import { reactive } from '@vue/composition-api'
import { frontDefaultValidators } from '@/services/form/validationService'
import { brcCurrency, formatToCents } from '@/services/utils'

export default {
  name: 'BroCoinExtraFields',
  components: {
    BRow,
    BCol,
    VxInput
  },
  setup () {
    const formValue = reactive({
      price: 0,
      currency_code: brcCurrency
    })

    const rules = {
      price: frontDefaultValidators.number
    }

    const frontToBackMapper = () => {
      return {
        ...formValue,
        price: formatToCents(formValue.price)
      }
    }

    return {
      formValue,
      rules,
      frontToBackMapper
    }
  }
}
</script>
